import { Controller } from "@hotwired/stimulus"
import Flickity from "flickity"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static values = {
    accessibility: Boolean,
    adaptiveHeight: Boolean,
    autoPlay: Boolean,
    autoPlaySpeed: Number,
    cellAlign: String,
    draggable: Boolean,
    groupCells: Boolean,
    initialIndex: Number,
    pageDots: Boolean,
    pauseAutoPlayOnHover: Boolean,
    percentPosition: Boolean,
    prevNextButtons: Boolean,
    setGallerySize: Boolean,
    wrapAround: Boolean
  }

  initialize() {
    this.flickity = new Flickity(this.element, this._flickityOptions)
    this.flickity.on("select", () => {
      this.dispatch("select", { element: this.flickity.selectedElement })
    })
  }

  connect() {
    useDispatch(this, { eventPrefix: "carousel" })
    this.resize()
  }

  resize() {
    if (this.flickity) this.flickity.resize()
  }

  get _flickityOptions() {
    return {
      accessibility: this.accessibilityValue,
      adaptiveHeight: this.adaptiveHeightValue,
      autoPlay: this.autoPlay,
      cellAlign: this.cellAlignValue,
      cellSelector: ".Carousel-Slide",
      draggable: this.draggableValue,
      groupCells: this.groupCellsValue,
      initialIndex: this.initialIndexValue,
      pageDots: this.pageDotsValue,
      pauseAutoPlayOnHover: this.pauseAutoPlayOnHoverValue,
      percentPosition: this.percentPositionValue,
      prevNextButtons: this.prevNextButtonsValue,
      setGallerySize: this.setGallerySizeValue,
      wrapAround: this.wrapAroundValue,
      selectedAttraction: 0.015
    }
  }

  get autoPlay() {
    if (this.hasAutoPlaySpeedValue) return this.autoPlaySpeedValue
    if (this.hasAutoPlayValue) return this.hasAutoPlayValue
    return false
  }
}

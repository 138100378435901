import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["togglable"]
  static values = {
    color: String
  }

  slideSelected(event) {
    const color = event.detail.element.firstChild.dataset.color
    const menuBlock = this.menuBlock
    if (menuBlock) {
      menuBlock.style.setProperty("--menu-color", color)
    }
  }

  get menuBlock() {
    return document.querySelector(".ViVii-MenuBlock")
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  customVar = "--menu-height"

  connect() {
    this.doc = document.documentElement
    this.update()
  }

  update() {
    requestAnimationFrame(() => {
      const menuHeight = this.element.offsetHeight
      if (menuHeight === this.previousMenuHeight) return
      this.doc.style.setProperty(this.customVar, `${menuHeight}px`)
      this.previousMenuHeight = menuHeight
    })
  }
}

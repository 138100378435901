import { Controller } from "@hotwired/stimulus"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import { post } from "@rails/request.js"

export default class extends Controller {
  static classes = ["closed"]
  static values = {
    viewsUrl: String
  }

  connect() {
    disableBodyScroll(this.element)
  }

  disconnect() {
    clearAllBodyScrollLocks()
  }

  close() {
    this.element.classList.add(this.closedClass)
    post(this.viewsUrlValue)
  }

  remove() {
    this.element.remove()
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iconLibrary"]
  static values = { icons: Object }
  static classes = ["started"]

  connect() {
    this.setupIcons()
  }

  setupIcons() {
    if (!this.hasIconLibraryTarget) return
    if (!this.hasIconsValue) return
    this.iconLibraryTarget.resolver = iconName => {
      return this.iconsValue[iconName]
    }
  }

  onPlay() {
    console.log("on play")
    this.element.classList.add(this.startedClass)
  }
}

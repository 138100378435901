import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["section", "link"]

  connect() {
    this.observeTops()
    this.observeBottoms()
    this.sectionsInView = []
  }

  observeTops() {
    if (!this.hasSectionTarget) return
    const observer = new IntersectionObserver(
      entries => {
        if (!entries.length) return
        entries.forEach(entry => {
          const boundingRect = entry.boundingClientRect
          const section = entry.target.parentElement
          const rootBounds = entry.rootBounds
          const sectionRect = section.getBoundingClientRect()

          if (!boundingRect || !rootBounds || !sectionRect) return

          if (
            boundingRect.top <= rootBounds.top &&
            sectionRect.bottom >= rootBounds.top
          ) {
            this.sectionIn(section)
          }

          if (boundingRect.top >= rootBounds.top) {
            this.sectionOut(section)
          }
        })
      },
      { threshold: [0, 1] }
    )
    const sentinels = this.addSentinels("top")
    if (sentinels) sentinels.forEach(sentinel => observer.observe(sentinel))
  }

  observeBottoms() {
    if (!this.hasSectionTarget) return
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const boundingRect = entry.boundingClientRect
          const section = entry.target.parentElement
          const rootBounds = entry.rootBounds
          const sectionRect = section.getBoundingClientRect()

          if (
            boundingRect.bottom >= rootBounds.top &&
            sectionRect.top <= rootBounds.top
          ) {
            this.sectionIn(section)
          }

          if (boundingRect.bottom <= rootBounds.top) {
            this.sectionOut(section)
          }
        })
      },
      { threshold: [0, 1] }
    )
    const sentinels = this.addSentinels("bottom")
    if (sentinels) sentinels.forEach(sentinel => observer.observe(sentinel))
  }

  addSentinels(position) {
    if (!this.hasSectionTarget) return
    return this.sectionTargets.map(section => {
      const sentinel = document.createElement("div")
      sentinel.classList.add("toc-sentinel", `toc-sentinel--${position}`)
      return section.appendChild(sentinel)
    })
  }

  sectionIn(section) {
    this.addIntersectingSection(section)
    this.dispatch("section:in", {
      detail: {
        section: section,
        sectionId: section.id,
        sectionsInView: this.sectionsInView
      }
    })
    this.sectionInView = section.id
  }

  sectionOut(section) {
    this.removeIntersectingSection(section)
    this.dispatch("section:out", {
      detail: {
        section: section,
        sectionId: section.id,
        sectionsInView: this.sectionsInView
      }
    })
    if (!this.sectionsInView.length) this.sectionInView = null
  }

  addIntersectingSection(section) {
    const index = this.sectionsInView.indexOf(section)
    if (index < 0) this.sectionsInView.push(section)
  }

  removeIntersectingSection(section) {
    const index = this.sectionsInView.indexOf(section)
    if (index > -1) this.sectionsInView.splice(index, 1)
  }

  set sectionInView(sectionId) {
    this.data.set("sectionInView", sectionId)
    this.linkTargets.forEach(item => {
      const isInView = item.hash === `#${sectionId}`
      item.setAttribute("aria-current", isInView.toString())
      if (isInView) this.dispatch("item:current", { detail: { item: item } })
    })
  }

  get sectionInView() {
    return this.sectionTargets.filter(section => {
      return section.id === this.data.get("sectionInView")
    })
  }
}

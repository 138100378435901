import { Controller } from "@hotwired/stimulus"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

export default class extends Controller {
  connect() {
    disableBodyScroll(this.element)
  }

  disconnect() {
    clearAllBodyScrollLocks()
  }

  close() {
    this.element.remove()
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  customVar = "--vh"

  connect() {
    this.doc = document.documentElement
    this.update()
  }

  update() {
    requestAnimationFrame(() => {
      const clientHeight = this.doc.clientHeight
      if (clientHeight === this.previousClientHeight) return
      this.doc.style.setProperty(this.customVar, `${clientHeight * 0.01}px`)
      this.previousClientHeight = clientHeight
    })
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  onSuccess(event) {
    const [, , xhr] = event.detail
    const fragment = this._documentFragment(xhr.response)
    this.element.replaceWith(fragment)
  }

  onError(event) {
    const [, , xhr] = event.detail
    const fragment = this._documentFragment(xhr.response)
    this.element.replaceWith(fragment)
  }

  _documentFragment(data) {
    const el = document.createElement("template")
    el.innerHTML = data
    return el.content
  }
}

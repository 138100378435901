import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  static classes = ["slideIn"]

  connect() {
    useIntersection(this)
  }

  appear() {
    this.element.classList.add(this.slideInClass)
  }
}

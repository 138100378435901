import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import TomSelect_sortable from "../extensions/tom-select/plugins/sortable.js"

TomSelect.define("sortable", TomSelect_sortable)

export default class extends Controller {
  static values = { sortable: Boolean }

  connect() {
    this.tomSelect = new TomSelect(this.element, {
      plugins: this.tomSelectPlugins,
      onItemAdd: function() {
        this.setTextboxValue("")
      }
    })
  }

  disconnect() {
    if (this.tomSelect) this.tomSelect.destroy()
  }

  get tomSelectPlugins() {
    let res = []
    if (this.isMultiple) res << "remove_button"
    if (this.sortableValue && this.isMultiple) res << "sortable"
    return res
  }

  get isMultiple() {
    return this.element.multiple
  }
}

import { Controller } from "@hotwired/stimulus"
import { useResize } from "stimulus-use"

export default class extends Controller {
  static targets = ["header"]
  static values = { headerHeight: Number }

  connect() {
    useResize(this)
    this.updateHeaderHeightProperty()
  }

  resize() {
    this.updateHeaderHeightProperty()
  }

  updateHeaderHeightProperty() {
    if (!this.hasHeaderTarget) return
    this.headerHeightValue = this.headerTarget.offsetHeight
  }

  headerHeightValueChanged(value, previousValue) {
    if (value === 0) return
    if (value === previousValue) return
    this.element.style.setProperty(
      "--header-height",
      `${this.headerTarget.offsetHeight}px`
    )
  }
}

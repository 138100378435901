import { Sortable } from "@shopify/draggable"

function getDom(query) {
  if (query instanceof HTMLElement) return query
  if (query.indexOf("<") > -1) {
    let div = document.createElement("div")
    div.innerHTML = query.trim()
    return div.firstChild
  }
  return document.querySelector(query)
}

export default function() {
  if (this.settings.mode !== "multi") return

  const originalLock = this.lock
  this.hook("instead", "lock", () => {
    if (this.sortable) this.sortable.disable()
    return originalLock.call(this)
  })

  const originalUnlock = this.unlock
  this.hook("instead", "unlock", () => {
    if (this.sortable) this.sortable.enable()
    return originalUnlock.call(this)
  })

  this.hook("after", "setupTemplates", () => {
    const originalRenderItem = this.settings.render.item
    this.settings.render.item = (data, escape) => {
      const rendered = getDom(originalRenderItem.call(this, data, escape))
      const handle = getDom("<div class='item__handle'>&vellip;</div>")
      rendered.prepend(handle)
      return rendered
    }
  })

  this.on("initialize", () => {
    this.sortable = new Sortable(this.control, {
      draggable: ".item",
      handle: ".item__handle"
    })
    this.sortable.on("sortable:stop", _ => {
      requestAnimationFrame(() => {
        const values = [...this.control.querySelectorAll("[data-value]")].map(
          item => item.dataset.value
        )
        this.setValue(values)
      })
    })
  })
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inquiry", "image"]

  // TODO: scroll to top when showing the form
  toggleInquiry() {
    this.inquiryTarget.hidden = !this.inquiryTarget.hidden
    this.imageTarget.hidden = !this.imageTarget.hidden
  }
}

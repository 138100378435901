import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { statusUrl: String }
  static targets = ["button"]
  static classes = ["syncing"]

  connect() {
    this.pollForChanges()
  }

  disable() {
    this.syncing = true
  }

  pollForChanges() {
    fetch(this.statusUrlValue)
      .then(response => response.json())
      .then(synchronization => (this.status = synchronization))
  }

  set status(synchronization) {
    if (synchronization._status === "running") {
      this.syncing = true
      this.buttonDisabled = true
    } else if (synchronization._status === "finished") {
      this.lastSyncedAt = synchronization.updated_at
      this.syncing = false
      this.buttonDisabled = synchronization.within_cool_down
    }
    if (this.buttonDisabled) setTimeout(() => this.pollForChanges(), 5000)
  }

  set syncing(value) {
    this.element.classList.toggle(this.syncingClass, value)
  }

  set lastSyncedAt(value) {
    this.timeAgo.setAttribute("datetime", value)
  }

  set buttonDisabled(value) {
    this.buttonTarget.disabled = value
  }

  get buttonDisabled() {
    return this.buttonTarget.disabled
  }

  get timeAgo() {
    return this.element.querySelector("time-ago")
  }
}

import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
  static values = {
    enableDate: Boolean,
    enableTime: Boolean
  }

  connect() {
    this.config = {
      altInput: true,
      altFormat: this.dateFormat,
      dateFormat: this.dateFormat,
      enableTime: this.enableTimeValue,
      noCalendar: !this.enableDateValue,
      time_24hr: this.enableTimeValue,
      showMonths: this.showMonths,
      appendTo: this.#appendTo
    }

    super.connect()
  }

  get showMonths() {
    if (!this.enableDateValue) return
    return 1
  }

  get dateFormat() {
    if (this.enableDateValue && this.enableTimeValue) {
      return "Y-m-d H:i"
    } else if (this.enableDateValue) {
      return "Y-m-d"
    } else {
      return "H:i"
    }
  }

  get #isInsideDialog() {
    return this.#dialog != null
  }

  get #appendTo() {
    if (this.#isInsideDialog) return this.#wrapperDiv
  }

  get #dialog() {
    return this.element.closest("dialog")
  }

  get #wrapperDiv() {
    return this.element.closest("div.time_picker")
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "template"]

  load() {
    this.container.appendChild(this.template)
  }

  get template() {
    return document.importNode(this.templateContent, true)
  }

  get templateContent() {
    return this.templateTarget.content
  }

  get container() {
    if (this.hasContainerTarget) {
      return this.containerTarget
    } else {
      return document.body
    }
  }
}
